export class RankedListItem {
    RankedListItemId!: number;
    RankedListId!: number;
    ItemName: string;
    CompanyName!: string;
    LinkUrl: string;
    ImageUrl: string;

    constructor(rankedListId: number) {
        this.RankedListId = rankedListId;
        this.ItemName = '';
        this.LinkUrl = '';
        this.ImageUrl = '';
    }

    static async ByRankedListId(rankedListId: number): Promise<Array<RankedListItem>> {
        const response = await fetch('/api/RankedListItemByRankListId?rankedListId=' + rankedListId);
        const data = await response.json();
        return data;
    }

    async Insert(): Promise<RankedListItem> {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this)
        };

        const response = await fetch('/api/RankedListItemInsert', settings);
        const data = await response.json();
        return data;
    }

    static async UpdateItemName(parameter: RankedListItem): Promise<RankedListItem> {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(parameter)
        };

        const response = await fetch('/api/RankedListItemUpdateItemName', settings);
        const data = await response.json();
        return data;


    }
}