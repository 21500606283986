export class RankedListItemScore {
    RankedListItemId!: number;
    Score!: number;
    Rank!: number;

    static async ByRankedListId(rankedListId: number): Promise<Array<RankedListItemScore>> {
        const response = await fetch('/api/RankedListItemScoreByRankedListId?rankedListId=' + rankedListId);
        const data = await response.json();
        return data;
    }

}