import React from 'react';
import { cloneDeep } from 'lodash';
import { Button, Input } from 'reactstrap';
import { MemberAuthenticationLogic } from '../logic/MemberAuthenticationLogic';
import { Helmet } from 'react-helmet';

type PropType = {
    IsLoggedIn: boolean,
    OnSuccessfulLogin: any,
    OnAfterSuccessfulLogin: any
}

type StateType = {
    MemberAuthentication: MemberAuthenticationLogic
};

export class Login extends React.Component<PropType, StateType> {
    state: StateType = {
        MemberAuthentication: new MemberAuthenticationLogic()
    };

    componentDidMount() {
    }

    handleLogIn() {

        this.state.MemberAuthentication.Login()
            .then(() => {
                this.props.OnSuccessfulLogin().then(() => {
                    this.props.OnAfterSuccessfulLogin();
                })
            })
    }

    handleUpdateUsername(event: React.ChangeEvent<HTMLInputElement>) {
        let updatedItem = cloneDeep(this.state.MemberAuthentication);
        updatedItem.Username = event.target.value;

        this.setState({
            MemberAuthentication: updatedItem
        })
    }

    handleUpdatePassword(event: React.ChangeEvent<HTMLInputElement>) {
        let updatedItem = cloneDeep(this.state.MemberAuthentication);
        updatedItem.Password = event.target.value;

        this.setState({
            MemberAuthentication: updatedItem
        })
    }

    handleCheckForEnter(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.keyCode === 13) {
            this.handleLogIn();
        }
    };

    render() {
        return <div>
            <Helmet>
                <title>Login to Bestest</title>
            </Helmet>
            Login: <Input placeholder='Username'
                value={this.state.MemberAuthentication.Username}
                onChange={this.handleUpdateUsername.bind(this)}
                onKeyDown={this.handleCheckForEnter.bind(this)}/><br />
            Passowrd: <Input placeholder='Password'
                type='password'
                value={this.state.MemberAuthentication.Password}
                onChange={this.handleUpdatePassword.bind(this)}
                onKeyDown={this.handleCheckForEnter.bind(this)} /><br />

            <Button color="primary" onClick={this.handleLogIn.bind(this)}>Login</Button>

        </div >
    }
}
