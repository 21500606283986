import React, { Component } from 'react';
import { Category as Logic_Category } from '../logic/Category';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { NavLink, withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { cloneDeep } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
// @ts-ignore
import urlSlug from 'url-slug'

interface PropType extends RouteComponentProps<any> {
};

type StateType = {
    IsLoading: boolean,
    CategoryList: Array<Logic_Category>;
    CategoryImage: Array<Array<string>>;
};

export class CategoryGrid extends Component<PropType, StateType> {
    state: StateType = {
        IsLoading: true,
        CategoryList: [],
        CategoryImage: []
    };

    componentDidMount() {
        Logic_Category.ByActive().then((a) => {
            this.setState({
                CategoryList: a,
                IsLoading: false
            });

            a?.forEach((currentCategory) => {

                currentCategory.GetImages().then((imageList) => {
                    let updatedItem = cloneDeep(this.state.CategoryImage);
                    updatedItem[currentCategory.CategoryId] = imageList;
                    this.setState({
                        CategoryImage: updatedItem
                    })
                })
            });
        });
    }

    handleGoToCategory(categoryPageUrl: string) {
        this.props.history.push(categoryPageUrl);
    }

    render() {

        if (this.state.IsLoading === true) {
            return <div>Loading...</div>
        }
        else if (!this.state.CategoryList) {
            return null;
        }
        else {
            return (
                <div>
                    <Container fluid={true}>
                        <Row xs="1" sm="2" md="4">
                            {
                                this.state.CategoryList.map((categoryItem, index) => {

                                    let categoryPageUrl: string = '/' + urlSlug(categoryItem.CategoryName) + '-C' + categoryItem.CategoryId;

                                    return <Col key={categoryItem.CategoryId} >
                                        <Card className='mb-3' style={{ cursor: 'pointer' }}>
                                            <CardBody onClick={this.handleGoToCategory.bind(this, categoryPageUrl)}>
                                                <Slider infinite autoplay speed={500} slidesToShow={1} slidesToScroll={1} arrows={false} touchMove={false} draggable={false}>
                                                    {this.state.CategoryImage[categoryItem.CategoryId] &&
                                                        this.state.CategoryImage[categoryItem.CategoryId].map((urlLink) => {
                                                            return <div key={urlLink}>
                                                                <img src={urlLink} style={{ width: '200px', height: '200px', objectFit: 'cover' }} alt={categoryItem.CategoryName} />
                                                            </div>
                                                        })}
                                                </Slider>
                                                <NavLink className='text-center'
                                                    to={categoryPageUrl}>
                                                    <h3>{categoryItem.CategoryName}</h3>
                                                </NavLink>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                })
                            }
                        </Row>
                    </Container>
                </div >
            );
        }
    }
}

export default withRouter(CategoryGrid);