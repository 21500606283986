import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from '../page/NavMenu';

type PropType = {
    IsLoggedIn: boolean,
    OnCheckLogin: any
}

type StateType = {
};

export class Layout extends Component<PropType, StateType> {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <NavMenu IsLoggedIn={this.props.IsLoggedIn} OnCheckLogin={this.props.OnCheckLogin} />
                <Container>
                    {this.props.children}
                </Container>
            </div>
        );
    }
}
