import React from 'react';
import { RankedList as Logic_RankedList } from '../logic/RankedList';
import { RankedListItem as Logic_RankedListItem } from '../logic/RankedListItem';
import { RankedListItem } from '../component/RankedListItem';
import { Button, Card, CardBody, CardText, Input, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { RankedListItemManager } from '../component/RankedListItemManager';
import { RankedListItemScore as Logic_RankedListItemScore } from '../logic/RankedListItemScore';
import { cloneDeep } from 'lodash';
import { Helmet } from 'react-helmet';
import { LoginCookieLogic } from '../logic/LoginCookieLogic';

import { RankedListLinkModal } from '../modal/RankedListLinkModal';


type PropType = {
    RankedList: Logic_RankedList,
    IsLoggedIn: boolean,
    OnCheckLogin: any,
    LoggedInMember: LoginCookieLogic | null
}

type StateType = {
    RankedListItem: Array<Logic_RankedListItem>
    RankedListItemScore: Array<Logic_RankedListItemScore>
    VisibleModal: string
};

export class RankedList extends React.Component<PropType, StateType> {
    state: StateType = {
        RankedListItem: [],
        RankedListItemScore: [],
        VisibleModal: ''
    };

    componentDidMount() {
        if (this.props.RankedList) {
            Logic_RankedListItem.ByRankedListId(this.props.RankedList.RankedListId)
                .then((a) => { this.setState({ RankedListItem: a }); });

            this.PullRankedListItemScore();
        }

    }

    PullRankedListItemScore() {
        Logic_RankedListItemScore.ByRankedListId(this.props.RankedList.RankedListId)
            .then((a) => { this.setState({ RankedListItemScore: a }); });
    }

    handleNewRankedListItem(newRankedListItem: Logic_RankedListItem) {
        let updatedItem = cloneDeep(this.state.RankedListItem);
        updatedItem.push(newRankedListItem);
        this.setState({ RankedListItem: updatedItem });

        this.PullRankedListItemScore();
    }

    handleUpdatedRankedListItem(updatedRankedListItem: Logic_RankedListItem) {
        let updatedItem = cloneDeep(this.state.RankedListItem);
        updatedItem = updatedItem.filter(a => a.RankedListItemId !== updatedRankedListItem.RankedListItemId);
        updatedItem.push(updatedRankedListItem);
        this.setState({ RankedListItem: updatedItem });

        this.PullRankedListItemScore();
    }

    handleShowModal(modalName: string) {
        this.setState({ VisibleModal: modalName });
    }

    render() {

        if (!this.props.RankedList) {
            return null;
        }
        else {
            return (
                <div>
                    <Helmet>
                        <title>{this.props.RankedList.Title}</title>
                    </Helmet>

                    {this.props.LoggedInMember != null && this.props.LoggedInMember.IsAdmin === true &&
                        <Card inverse color='info'>
                            <CardBody className='p-1'>
                                <Button size='sm' className='float-right' onClick={this.handleShowModal.bind(this, 'ManageRankedListUrl')}>Manage 3rd Party Links</Button>
                            </CardBody>
                        </Card>
                    }

                    <h1>{this.props.RankedList.Title}</h1>
                    <div>{this.props.RankedList.Description}</div>

                    <ListGroup>
                        {
                            this.state.RankedListItemScore &&
                            this.state.RankedListItemScore.map((rankedListItemScore, index) => {

                                let rankedListItem = this.state.RankedListItem.find(obj => {
                                    return obj.RankedListItemId === rankedListItemScore.RankedListItemId
                                })

                                return <ListGroupItem key={rankedListItemScore.RankedListItemId} >
                                    <RankedListItem IsLoggedIn={this.props.IsLoggedIn} LoggedInMember={this.props.LoggedInMember}
                                        OnCheckLogin={this.props.OnCheckLogin}
                                        RankedListItemScore={rankedListItemScore} RankedListItem={rankedListItem}
                                        OnUpdatedRankedListItem={this.handleUpdatedRankedListItem.bind(this)} />
                                </ListGroupItem>
                            })
                        }
                        {this.props.LoggedInMember != null && this.props.LoggedInMember.IsAdmin === true &&
                            <ListGroupItem>
                                <RankedListItemManager RankedList={this.props.RankedList} OnNewRankedListItem={this.handleNewRankedListItem.bind(this)} />
                            </ListGroupItem>
                        }
                    </ListGroup>


                    <RankedListLinkModal
                        RankedListId={this.props.RankedList.RankedListId}
                        IsLoggedIn={this.props.IsLoggedIn}
                        IsModalOpen={this.state.VisibleModal === 'ManageRankedListUrl'}
                        OnAfterSuccessfulLogin={this.handleShowModal.bind(this, '')}
                        OnSuccessfulLogin={this.handleShowModal.bind(this, '') }
                        OnToggleModal={this.handleShowModal.bind(this, '') } />
                </div>
            );
        }
    }
}
