import { plainToClass } from 'class-transformer';

export class RankedList {

    RankedListId!: number;
    CategoryId: number;
    Title!: string;
    Description!: string;

    constructor(categoryId: number) {
        this.CategoryId = categoryId;
        this.Title = '';
        this.Description = '';
    }

    async Insert() : Promise<RankedList> {

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this)
        };

        const response = await fetch('/api/RankedListInsert', settings);
        const data = await response.json();
        return data;

    }


    static ByHome(pageNumber: number): Array<RankedList> {
        let result: Array<RankedList> = new Array<RankedList>();

        return result;
    }

    static async ByRankedListId(rankedListId: number): Promise<RankedList | null> {
        const response = await fetch('/api/RankedListByRankListId?rankedListId=' + rankedListId);
        const data: RankedList = await response.json();

        let result: RankedList | null = null;
        result = plainToClass(RankedList, data);
        return result;
    }

    static async ByCategoryId(categoryId: number): Promise<Array<RankedList> | null> {
        const response = await fetch('/api/RankedListByCategoryId?categoryId=' + categoryId);
        const data = await response.json();

        let result: Array<RankedList> = [];

        data.forEach((jsonData: RankedList) => {
            result.push(plainToClass(RankedList, jsonData));
        })

        return result;
    }

    async GetImages(): Promise<Array<string>> {
        const response = await fetch('/api/RankedListItemByImageUrlByRankedListId?rankedListId=' + this.RankedListId);
        const data = await response.json();
        return data;
    }
}