import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { Login } from '../component/Login';

interface PropType extends RouteComponentProps<any> {
    IsLoggedIn: boolean,
    OnCheckLogin: any,
};

export class LoginPage extends Component<PropType> {
    static displayName = LoginPage.name;

    handleAfterSuccessfulLogin() {
        this.props.history.push('/');
    }

    render() {
        return (
            <div>
                <Login IsLoggedIn={this.props.IsLoggedIn} OnSuccessfulLogin={this.props.OnCheckLogin} OnAfterSuccessfulLogin={this.handleAfterSuccessfulLogin.bind(this)} />
            </div>
        );
    }
}
