import React, { Component } from 'react';
import { CategoryGrid } from '../component/CategoryGrid';
import { RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';

interface PropType extends RouteComponentProps<any> {
};

export class Home extends Component<PropType> {
    static displayName = Home.name;



    render() {
        return (
            <div>
                <Helmet>
                    <title>Bestest</title>
                </Helmet>

                <CategoryGrid history={this.props.history} location={this.props.location} match={ this.props.match }/>
            </div>
        );
    }
}
