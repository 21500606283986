import React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { MemberAuthenticationLogic } from '../logic/MemberAuthenticationLogic'

type PropType = {
    IsLoggedIn: boolean,
    OnCheckLogin: any
}

type StateType = {
    collapsed: boolean
}

export class NavMenu extends React.Component<PropType, StateType> {
    static displayName = NavMenu.name;

    constructor(props: PropType | Readonly<PropType>) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    handleLogOut() {
        MemberAuthenticationLogic.Logout().then(() => {
            this.props.OnCheckLogin();
        })
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">Bestest <Badge color="warning">the very best.</Badge></NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                                </NavItem>
                                {this.props.IsLoggedIn === false &&
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/Login">Login</NavLink>
                                    </NavItem>
                                }
                                {this.props.IsLoggedIn === true &&
                                    <NavItem>
                                        <NavLink href='#' className="text-dark" onClick={this.handleLogOut.bind(this)}>Logout</NavLink>
                                    </NavItem>
                                }
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header >
        );
    }
}
