import { cloneDeep } from 'lodash';
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Input, Button } from 'reactstrap';
import { Login } from '../component/Login';
import { RankedListLinkLogic } from '../logic/RankedListLinkLogic';

type PropType = {
    RankedListId: number,
    IsModalOpen: boolean,
    OnToggleModal: any,
    IsLoggedIn: boolean,
    OnSuccessfulLogin: any,
    OnAfterSuccessfulLogin: any
}

type StateType = {
    Url: string,
    UpdateInProgress: boolean,
    RankedListLinkList: RankedListLinkLogic[]
};

export class RankedListLinkModal extends Component<PropType, StateType> {
    state: StateType = {
        Url: '',
        UpdateInProgress: false,
        RankedListLinkList: []
    };

    componentDidMount() {
        this.getRankedListLinkList();
    }

    handleUpdateLinkUrl(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            Url: event.target.value
        })
    }

    handleUpdateNewRankedItem() {
        this.setState({
            UpdateInProgress: true

        }, () => {

            let newRankedListLinkLogic = new RankedListLinkLogic(this.props.RankedListId, this.state.Url);
            newRankedListLinkLogic.Insert()
                .then(() => {
                    this.getRankedListLinkList();
                })

            //this.state.NewRankedListItem.Insert()
            //    .then((newRankedListItem) => {
            //        this.props.OnNewRankedListItem(newRankedListItem);
            //        this.setState({
            //            NewRankedListItem: new Logic_RankedListItem(this.props.RankedList.RankedListId),
            //            UpdateInProgress: false
            //        })
            //    });


        })
    }

    getRankedListLinkList() {
        if (this.props.RankedListId) {
            RankedListLinkLogic.ByRankedListId(this.props.RankedListId)
                .then((newRankedListLinkList) => {
                    this.setState({
                        RankedListLinkList: newRankedListLinkList
                    })
                })
        }
    }

    render() {
        return <Modal isOpen={this.props.IsModalOpen} toggle={this.props.OnToggleModal}>
            <ModalHeader toggle={this.props.OnToggleModal}>Manage 3rd Party URLs</ModalHeader>
            <ModalBody>
                <Input placeholder='URL (http://)'
                    value={this.state.Url}
                    onChange={this.handleUpdateLinkUrl.bind(this)} />
                <Button color='primary'
                    disabled={this.state.UpdateInProgress || this.state.Url.length === 0}
                    onClick={this.handleUpdateNewRankedItem.bind(this)}>Check</Button>

                {this.state.RankedListLinkList.map((link, index) => (
                    <div key={link.RankedListLinkId}>{link.LinkUrl}</div>
                    ))
                }
            </ModalBody>
        </Modal>
    }
}
