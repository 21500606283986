import { plainToClass } from 'class-transformer';

export class Category {
    CategoryId!: number;
    CategoryName!: string;

    static async ByActive(): Promise<Array<Category>> {
        const response = await fetch('/api/CategoryByActive');
        const data: Array<Category> = await response.json();

        let result: Array<Category> = [];
        data.forEach((jsonData: Category) => {
            result.push(plainToClass(Category, jsonData));
        })

        return result;
    }

    async GetImages(): Promise<Array<string>> {
        const response = await fetch('/api/RankedListItemByImageUrlByCategoryId?categoryId=' + this.CategoryId);
        const data = await response.json();
        return data;
    }
}