import { plainToClass } from 'class-transformer';





export class RankedListLinkLogic {
    RankedListLinkId!: number;
    RankedListId: number;
    LinkUrl: string;
    CanonicalUrl!: string;
    LastProcessDate!: Date;


    constructor(rankedListId: number, linkUrl: string) {
        this.RankedListId = rankedListId;
        this.LinkUrl = linkUrl;
    }

    //static async Insert(rankedListId: number, url: string) : Promise<RankedListLinkLogic> {

    //    const settings = {
    //        method: 'POST',
    //        headers: {
    //            Accept: 'application/json',
    //            'Content-Type': 'application/json',
    //        },
    //        body: JSON.stringify(this)
    //    };

    //    const response = await fetch('/api/RankedListLinkInsert', settings);
    //    const data = await response.json();
    //    return data;
    //}

    async Insert(): Promise<Array<RankedListLinkLogic>> {

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this)
        };

        const response = await fetch('/api/RankedListLinkInsert', settings);
        const data = await response.json();
        return data;

    }

    static async ByRankedListId(rankedListId: number): Promise<Array<RankedListLinkLogic>> {
        const response = await fetch('/api/RankedListLinkByRankListId?rankedListId=' + rankedListId);
        const data: Array<RankedListLinkLogic> = await response.json();

        let result: Array<RankedListLinkLogic>;
        result = data;
        //result = plainToClass(RankedListLinkLogic, data);
        return result;
    }
}