import React from 'react';
import { RankedListItem as Logic_RankedListItem } from '../logic/RankedListItem';
import { RankedListItemScore as Logic_RankedListItemScore } from '../logic/RankedListItemScore';

import { Button, Input, InputGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { cloneDeep } from 'lodash';
import { LoginModal } from '../modal/LoginModal';
import { MemberLogic } from '../logic/MemberLogic';
import { LoginCookieLogic } from '../logic/LoginCookieLogic';

type PropType = {
    RankedListItem: Logic_RankedListItem | undefined,
    RankedListItemScore: Logic_RankedListItemScore,
    OnUpdatedRankedListItem: any,
    IsLoggedIn: boolean,
    LoggedInMember: LoginCookieLogic | null,
    OnCheckLogin: any
}

type StateType = {
    IsEditing: boolean,
    RankedListItem: Logic_RankedListItem | null,
    ShowLoginModal: boolean
};

export class RankedListItem extends React.Component<PropType, StateType> {

    constructor(props: PropType) {
        super(props)

        this.state = {
            IsEditing: false,
            RankedListItem: null,
            ShowLoginModal: false
        }

    }

    handleSetEditMode() {
        if (this.props.RankedListItem) {
            this.setState({
                IsEditing: true,
                RankedListItem: cloneDeep(this.props.RankedListItem)
            });
        }
    }

    handleSaveChanges() {
        this.setState({ IsEditing: false },
            () => {
                if (this.state.RankedListItem) {
                    console.log('save')
                    Logic_RankedListItem.UpdateItemName(this.state.RankedListItem)
                        .then((updatedRankedList) => {
                            this.props.OnUpdatedRankedListItem(updatedRankedList);
                            this.setState({ RankedListItem: null });
                        });
                }
            }
        );


    }

    handleUpdateItemName(event: React.ChangeEvent<HTMLInputElement>) {
        if (this.state.RankedListItem) {
            let updatedItem = cloneDeep(this.state.RankedListItem);
            updatedItem.ItemName = event.target.value;

            this.setState({
                RankedListItem: updatedItem
            })
        }
    }

    handleIsLoggedIn(): Promise<boolean> {
        return MemberLogic.IsLoggedIn()
            .then(a => { return a; });
    }

    handleToggleLogin() {
        this.setState({ ShowLoginModal: !this.state.ShowLoginModal })
    }

    handleRankedListItemVote() {

        if (this.props.IsLoggedIn === false) {
            this.setState({
                ShowLoginModal: true
            })
        }
        else {

            console.log('voted!');

        }

    }

    render() {

        return (
            <div>
                {this.props.RankedListItem &&
                    <div className='d-flex'>
                        <div className='mr-3 align-top'>
                            <Button color='warning' onClick={this.handleRankedListItemVote.bind(this)}>
                                <FontAwesomeIcon icon={faChevronUp} size='3x' />
                                <div>{this.props.RankedListItemScore.Score}</div>
                            </Button>
                        </div>
                        <div className='flex-grow-1'>
                            <div>
                                {this.props.LoggedInMember != null && this.props.LoggedInMember.IsAdmin === true && this.state.IsEditing === false &&
                                    <button className='btn btn-sm btn-primary' onClick={this.handleSetEditMode.bind(this)}>Edit</button>
                                }
                                {this.state.IsEditing === true &&
                                    <React.Fragment>
                                        <button className='btn btn-sm btn-primary' onClick={this.handleSaveChanges.bind(this)}>Save</button>
                                        <button className='btn btn-sm btn-danger' onClick={this.handleSaveChanges.bind(this)}>Cancel</button>
                                    </React.Fragment>
                                }
                            </div>
                            {this.state.IsEditing === false &&
                                <React.Fragment>
                                    <h2>
                                        {this.props.RankedListItem.ItemName}
                                    </h2>
                                    {this.props.RankedListItem.ImageUrl &&
                                        <img src={this.props.RankedListItem.ImageUrl} width='200px' alt={this.props.RankedListItem.ItemName} />
                                    }
                                </React.Fragment>
                            }
                            {this.state.IsEditing === true && this.state.RankedListItem &&
                                <React.Fragment>
                                    <h2>
                                        <InputGroup size='lg'>
                                            <Input placeholder='URL (http://)'
                                                value={this.state.RankedListItem.ItemName}
                                                onChange={this.handleUpdateItemName.bind(this)} />
                                        </InputGroup>
                                    </h2>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                }

                {
                    <LoginModal IsModalOpen={this.props.IsLoggedIn === false && this.state.ShowLoginModal === true}
                        OnToggleModal={this.handleToggleLogin.bind(this)}
                        IsLoggedIn={this.props.IsLoggedIn}
                        OnSuccessfulLogin={this.props.OnCheckLogin}
                        OnAfterSuccessfulLogin={this.handleRankedListItemVote.bind(this)} />
                }
            </div>
        );
    }
}
