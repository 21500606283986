import { LoginCookieLogic } from './LoginCookieLogic';

export class MemberAuthenticationLogic {
    Username: string;
    Password: string;

    constructor() {
        this.Username = '';
        this.Password ='';
    }

    static async IsLoggedIn(): Promise<LoginCookieLogic> {
        const response = await fetch('/api/IsLoggedIn');
        const data: LoginCookieLogic = await response.json();
        return data;
    }

    async Login(): Promise<boolean> {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this)
        };

        const response = await fetch('/api/Login', settings);
        const data = await response.json();
        return data;
    }

    static async Logout(): Promise<boolean> {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        };

        const response = await fetch('/api/Logout', settings);
        const data = await response.json();
        return data;
    }
}