import React from 'react';
import { RankedList as Logic_RankedList } from '../logic/RankedList';
import { NavLink, withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { cloneDeep } from 'lodash';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { RankedListManager } from '../component/RankedListManager';
import { Helmet } from 'react-helmet';
// @ts-ignore
import urlSlug from 'url-slug'
import { LoginCookieLogic } from '../logic/LoginCookieLogic';


type RouteParam = {
    CategoryName: string | undefined,
    CategoryId: string | undefined
}

type RouteProps = RouteComponentProps<RouteParam>;

type PropType = {
    IsLoggedIn: boolean,
    LoggedInMember: LoginCookieLogic | null,
    OnCheckLogin: any
};

type StateType = {
    RankedListList: Array<Logic_RankedList> | null;
    RankedListImage: Array<Array<string>>;
};

export class CategoryPage extends React.Component<RouteProps & PropType, StateType> {
    state: StateType = {
        RankedListList: [],
        RankedListImage: []
    };

    componentDidMount() {
        if (this.props.match.params.CategoryId) {
            Logic_RankedList.ByCategoryId(Number(this.props.match.params.CategoryId))
                .then((a) => {
                    this.setState({ RankedListList: a });

                    a?.forEach((currentRankedList) => {

                        currentRankedList.GetImages().then((imageList) => {
                            let updatedItem = cloneDeep(this.state.RankedListImage);
                            updatedItem[currentRankedList.RankedListId] = imageList;
                            this.setState({
                                RankedListImage: updatedItem
                            })
                        })
                    });
                });
        }
    }

    handleGoToRankedList(rankedListUrl: string, event: React.MouseEvent<HTMLElement>) {
        this.props.history.push(rankedListUrl);
        event.preventDefault();
    }

    handleAddRankedList(newRankedList: Logic_RankedList) {
        var updatedRankedListList = cloneDeep(this.state.RankedListList);

        if (updatedRankedListList == null) {
            updatedRankedListList = Array<Logic_RankedList>();
        }

        updatedRankedListList.push(newRankedList)

        this.setState({ RankedListList: updatedRankedListList })
    }

    render() {

        return (
            <div>
                <Helmet>
                    <title style={{ textTransform: 'capitalize' }}>{this.props.match.params.CategoryName?.toLowerCase()}</title>
                </Helmet>
                <h2 style={{ textTransform: 'capitalize' }}>{this.props.match.params.CategoryName?.toLowerCase()}</h2>
                <Container fluid={true}>
                    <Row xs="1" sm="2" md="4">

                        {this.state.RankedListList && this.state.RankedListList.map((currentRankedList, index) => {

                            let rankedListUrl: string = '/' + urlSlug(this.props.match.params.CategoryName) + '-C' + this.props.match.params.CategoryId + '/' + urlSlug(currentRankedList.Title) + '-L' + currentRankedList.RankedListId;

                            return (
                                <Col key={currentRankedList.RankedListId}>
                                    <Card className='mb-3' style={{ cursor: 'pointer' }}>
                                        <CardBody onClick={this.handleGoToRankedList.bind(this, rankedListUrl)}>
                                            <Slider infinite autoplay speed={500} slidesToShow={1} slidesToScroll={1} arrows={false} touchMove={false} draggable={false}>
                                                {this.state.RankedListImage[currentRankedList.RankedListId] &&
                                                    this.state.RankedListImage[currentRankedList.RankedListId].map((urlLink) => {
                                                        return <div key={urlLink}>
                                                            <img src={urlLink} style={{ width: '200px', height: '200px', objectFit: 'cover' }} alt={currentRankedList.Title} />
                                                        </div>
                                                    })}
                                            </Slider>

                                            <NavLink className='text-center'
                                                to={rankedListUrl}>
                                                <h3>{currentRankedList.Title}</h3>
                                            </NavLink>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        })}
                    </Row>
                    <Row>
                        {this.props.IsLoggedIn === true && this.props.match.params.CategoryId != undefined &&
                            <RankedListManager CategoryId={parseInt(this.props.match.params.CategoryId)} OnNewRankedList={this.handleAddRankedList.bind(this)} />
                        }
                    </Row>
                </Container>
            </div>
        );

    }
}

export default withRouter(CategoryPage);