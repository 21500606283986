import React from 'react';
import { RankedList as Logic_RankedList } from '../logic/RankedList';
import { RankedListItem } from '../logic/RankedListItem';
import { RankedList } from '../component/RankedList';
import { RouteComponentProps } from 'react-router';
import { LoginCookieLogic } from '../logic/LoginCookieLogic';


type Param = {
    CategoryName: string | undefined,
    CategoryId: string | undefined,
    Title: string | undefined,
    RankedListId: string | undefined
}

type RouteProps = RouteComponentProps<Param>;

type PropType = {
    IsLoggedIn: boolean,
    OnCheckLogin: any,
    LoggedInMember: LoginCookieLogic | null
};

type StateType = {
    Count: number,
    RankedListItem: Array<RankedListItem>;
    RankedList: Logic_RankedList | null;
};

export class RankedListPage extends React.Component<RouteProps & PropType, StateType> {
    state: StateType = {
        Count: 0,
        RankedListItem: [],
        RankedList: null
    };

    componentDidMount() {
        let rankedListId = this.props.match.params.RankedListId;
        if (rankedListId) {
            Logic_RankedList.ByRankedListId(Number(rankedListId))
                .then((a) => { this.setState({ RankedList: a }); });
        }
    }

    render() {

        return (
            <div>
                {this.state.RankedList &&
                    <RankedList IsLoggedIn={this.props.IsLoggedIn} LoggedInMember={this.props.LoggedInMember} OnCheckLogin={this.props.OnCheckLogin}
                        RankedList={this.state.RankedList} />}
            </div>
        );
    }
}
