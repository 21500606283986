import React from 'react';
import { RankedList as RankedListLogic } from '../logic/RankedList';
import { Row, Col, Button, Input } from 'reactstrap';
import { cloneDeep } from 'lodash';


type PropType = {
    CategoryId: number
    OnNewRankedList: any
}

type StateType = {
    NewRankedList: RankedListLogic;
    UpdateInProgress: boolean
};

export class RankedListManager extends React.Component<PropType, StateType> {

    constructor(props: PropType | Readonly<PropType>) {
        super(props);

        this.state = {
            NewRankedList: new RankedListLogic(this.props.CategoryId),
            UpdateInProgress: false
        };
    }

    handleCheck() {


    }

    handleUpdateNewRanked() {
        this.setState({
            UpdateInProgress: true
        }, () => {
            this.state.NewRankedList.Insert()
                .then((newRankedList) => {
                    this.props.OnNewRankedList(newRankedList);
                    this.setState({
                        NewRankedList: new RankedListLogic(this.props.CategoryId),
                        UpdateInProgress: false
                    })
                });
        })
    }

    handleUpdateTitle(event: React.ChangeEvent<HTMLInputElement>) {
        let updatedItem = cloneDeep(this.state.NewRankedList);
        updatedItem.Title = event.target.value;

        this.setState({
            NewRankedList: updatedItem
        })
    }

    handleUpdateDescription(event: React.ChangeEvent<HTMLInputElement>) {
        let updatedItem = cloneDeep(this.state.NewRankedList);
        updatedItem.Description = event.target.value;

        this.setState({
            NewRankedList: updatedItem
        })
    }

    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <Input placeholder='Title'
                            value={this.state.NewRankedList.Title}
                            onChange={this.handleUpdateTitle.bind(this)} />
                        <Input placeholder='Description'
                            value={this.state.NewRankedList.Description}
                            onChange={this.handleUpdateDescription.bind(this)} />
                        <Button color='primary'
                            disabled={this.state.UpdateInProgress || this.state.NewRankedList.Title.length === 0}
                            onClick={this.handleUpdateNewRanked.bind(this)}>Check</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}
