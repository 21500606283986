import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Login } from '../component/Login';

type PropType = {
    IsModalOpen: boolean,
    OnToggleModal: any,
    IsLoggedIn: boolean,
    OnSuccessfulLogin: any,
    OnAfterSuccessfulLogin: any
}

type StateType = {
};

export class LoginModal extends Component<PropType, StateType> {
    state: StateType = {
    };

    componentDidMount() {
    }

    render() {
        return <Modal isOpen={this.props.IsModalOpen} toggle={this.props.OnToggleModal}>
            <ModalHeader toggle={this.props.OnToggleModal}>Login</ModalHeader>
            <ModalBody>
                <Login IsLoggedIn={this.props.IsLoggedIn} OnSuccessfulLogin={this.props.OnSuccessfulLogin} OnAfterSuccessfulLogin={this.props.OnAfterSuccessfulLogin} />
            </ModalBody>
        </Modal>
    }
}
