import React, { Component } from 'react';
import 'babel-polyfill'
import { Route } from 'react-router';
import { Layout } from './component/Layout';
import { Home } from './page/Home';
import { RankedListPage } from './page/RankedListPage';
import { LoginPage } from './page/LoginPage';
import { CategoryPage } from './page/CategoryPage';
import 'reflect-metadata';
import './custom.css'
import { MemberAuthenticationLogic } from './logic/MemberAuthenticationLogic';
import { LoginCookieLogic } from './logic/LoginCookieLogic';

type PropType = {
}

type StateType = {
    IsLoggedIn: boolean,
    LoggedInMember: LoginCookieLogic | null
};

export default class App extends Component<PropType, StateType> {
    static displayName = App.name;

    constructor(props: PropType) {
        super(props)

        this.state = {
            IsLoggedIn: false,
            LoggedInMember: null
        }      

    }

    componentDidMount() {
        MemberAuthenticationLogic.IsLoggedIn().then(loggedInMember => {
            this.setState({
                IsLoggedIn: loggedInMember.Username !== null ? true : false,
                LoggedInMember: loggedInMember.Username !== null ? loggedInMember : null
            })
        })
    }

    async handleCheckLogin() : Promise<boolean> {
        return MemberAuthenticationLogic.IsLoggedIn().then(loggedInMember => {
            this.setState({
                IsLoggedIn: loggedInMember.Username !== null ? true : false,
                LoggedInMember: loggedInMember.Username !== null ? loggedInMember : null
            })

            return loggedInMember != null ? true : false;
        })
    }

    render() {
        return (
            <Layout IsLoggedIn={this.state.IsLoggedIn} OnCheckLogin={this.handleCheckLogin.bind(this)}>
                <Route exact path='/' component={Home} />
                <Route exact path='/Login'
                    render={(props) => (
                        <LoginPage {...props} IsLoggedIn={this.state.IsLoggedIn} OnCheckLogin={this.handleCheckLogin.bind(this)} />
                    )} />
                <Route exact path='/:CategoryName-C:CategoryId/:Title-L:RankedListId'
                    render={(props) => (
                        <RankedListPage {...props} IsLoggedIn={this.state.IsLoggedIn} LoggedInMember={this.state.LoggedInMember} OnCheckLogin={ this.handleCheckLogin.bind(this)} />
                    )} />
                <Route exact path='/:CategoryName-C:CategoryId'
                    render={(props) => (
                        <CategoryPage {...props} IsLoggedIn={this.state.IsLoggedIn} LoggedInMember={this.state.LoggedInMember} OnCheckLogin={this.handleCheckLogin.bind(this)} />
                    )} />
            </Layout>
        );
    }
}
