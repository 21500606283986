import React from 'react';
import { RankedListItem as Logic_RankedListItem } from '../logic/RankedListItem';
import { RankedList as Logic_RankedList } from '../logic/RankedList';
import { Row, Col, Button, Input } from 'reactstrap';
import { cloneDeep } from 'lodash';


type PropType = {
    RankedList: Logic_RankedList,
    OnNewRankedListItem: any
}

type StateType = {
    NewRankedListItem: Logic_RankedListItem;
    UpdateInProgress: boolean
};

export class RankedListItemManager extends React.Component<PropType, StateType> {

    constructor(props: PropType | Readonly<PropType>) {
        super(props);

        this.state = {
            NewRankedListItem: new Logic_RankedListItem(props.RankedList.RankedListId),
            UpdateInProgress: false
        };
    }

    handleCheck() {


    }

    handleUpdateNewRankedItem() {
        this.setState({
            UpdateInProgress: true
        }, () => {
            this.state.NewRankedListItem.Insert()
                .then((newRankedListItem) => {
                    this.props.OnNewRankedListItem(newRankedListItem);
                    this.setState({
                        NewRankedListItem: new Logic_RankedListItem(this.props.RankedList.RankedListId),
                        UpdateInProgress: false
                    })
                });
        })
    }

    handleUpdateLinkUrl(event: React.ChangeEvent<HTMLInputElement>) {
        let updatedItem = cloneDeep(this.state.NewRankedListItem);
        updatedItem.LinkUrl = event.target.value;

        this.setState({
            NewRankedListItem: updatedItem
        })
    }

    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <Input placeholder='URL (http://)'
                            value={this.state.NewRankedListItem.LinkUrl}
                            onChange={this.handleUpdateLinkUrl.bind(this)} />
                        <Button color='primary'
                            disabled={this.state.UpdateInProgress || this.state.NewRankedListItem.LinkUrl.length === 0}
                            onClick={this.handleUpdateNewRankedItem.bind(this)}>Check</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}
